import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { config } from '@keytrade/functions';
import { Badge } from '@keytrade/components-badge';

import Container from '@/components/Container';
import { mediaQuery } from '@/utils/helpers';
import {
  isDarkTheme,
  getFontColorByBackground,
  getBackgroundColor,
} from '@/utils/StyleUtils';
import Title from '@/components/Title';
import RichText, { RichTextType } from '@/components/Richtext';
import About, { AboutType } from '@/components/About';
import FeaturedLink, { FeaturedLinkData } from '@/components/FeaturedLink';
import InternalLink from '@/components/InternalLink';
import { LinkStyle } from '@/components/Link';

import Image, { ImageType } from '@/components/Image';

const { list: colors } = config.colors;
const ContentBlock = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 1.6rem;
  border: 1px solid ${colors.BlueLight};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05),
    0px 2px 7px 0px rgba(0, 0, 0, 0.05);
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  background: white;
  ${mediaQuery.md(`
  padding: 3.2rem;
  border-radius: 2.4rem;
max-width:776px;
margin-left:auto;
margin-right:auto;
`)}
  ${mediaQuery.lg(`
  max-width:100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap:2.4rem;
  grid-template-rows: auto;
  border-radius: 2.4rem;
  padding: 3.2rem;
  margin-left: -1px;
  margin-right: -1px;
`)}
`;
const TitleContainer = styled.div`
  grid-column: 1;
  word-break: break-word;
`;

const Description = styled.div`
  grid-column: 1;
  word-break: break-word;
  margin-bottom: 3.2rem;
`;

const ContentWrapper = styled.div`
  margin-top: 24px;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div:last-child {
    > div:last-child {
      margin-bottom: 0;
      h1 {
        margin-bottom: 0;
      }
    }
  }
  ${({ hasBacklink }) =>
    hasBacklink
      ? `> span:first-child {
      margin-bottom:3.2rem;
    }`
      : mediaQuery.lg(`
      margin:3.2rem 0;
`)}
}
`;
const ImageContent = styled.div`
  margin-bottom: 2rem;
  ${mediaQuery.lg(`
  margin-bottom: 0;
  margin-right:1.6rem;
  width:100%;
  `)}
`;
const MainImage = styled(Image)`
  position: relative;
  border-radius: 1.2rem;
`;

type FeaturedLinkWrapperProps = {
  background: string;
};

const FeaturedLinkWrapper = styled.div<FeaturedLinkWrapperProps>`
  grid-column: 1;
  display: block;
  grid-row-end: 7;
  padding: 24px 0 0;
  max-width: 500px;
  >*
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    background-color: ${({ background }) => background || 'white'};
    
    &:first-of-type
    {
      border-radius: 6px 6px 0px 0px;
    }
    
    &:last-of-type
    {
      border-radius: 0px 0px 6px 6px;
    }

    &:only-child
    {
      border-radius: 6px;
    }
  }
  ${mediaQuery.lg(`
  padding: 24px 0 60px 0;
  `)};
}`;

const TopBlockWrapper = styled.div`
  position: relative;
  ${({ seminar }) =>
    seminar
      ? `margin-bottom: 3.2rem;
    ${mediaQuery.lg(`
      margin-bottom: 4.8rem;
    `)}`
      : `margin-bottom: -6.8rem;
    ${mediaQuery.lg(`
      margin-bottom: -11.6rem;
    `)}
    `}
  padding-top: 7.2rem;
  ${mediaQuery.md(`
    padding-top: 8.8rem;
  `)};
  ${mediaQuery.lg(`
    padding-top: 14.6rem;
  `)};
  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: calc(50% + 14.6rem / 2);
    background-color: ${getBackgroundColor('Light blue')};
  }
`;
const TextContent = styled.div`
  flex-grow: 1;
  padding-top: 2.5rem;
`;

const TagsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export type RoundedTopBlockType = RichTextType & {
  title: string;
  backlink?: {
    url?: string;
    label?: string;
  };
  about: AboutType;
  image: ImageType;
  pageColor?: string;
  featuredLinks?: Array<FeaturedLinkData>;
  seminar?: boolean;
  tags?: Array<string>;
  children?: ReactNode;
  ctaBlock?: ReactNode;
};

type Props = RoundedTopBlockType;

const RoundedTopBlock: React.FC<Props> = (props) => {
  const backgroundColor: string = props.pageColor;
  const dark: boolean = isDarkTheme(backgroundColor);
  const fontColor = getFontColorByBackground(backgroundColor);
  const options: any = { theme: dark ? 'dark' : 'white' };
  const featuredBackgroundColor: string = dark
    ? getBackgroundColor('Blue Dark')
    : getBackgroundColor('White');
  const hasBacklink = !!props.backlink;
  return (
    <TopBlockWrapper seminar={props.seminar}>
      <Container>
        <ContentBlock>
          <ImageContent>
            <MainImage {...props.image} />
          </ImageContent>
          <InfoContent hasBacklink={hasBacklink}>
            {props.backlink && (
              <InternalLink
                to={`/${props.backlink.url}`}
                linkStyle={LinkStyle.BACK}
              >
                {props.backlink.label}
              </InternalLink>
            )}

            <TextContent>
              {props.about && <About data={props.about} dark={dark} />}
              <TitleContainer>
                <Title
                  size={props.seminar ? 'xl' : 'xxxl'}
                  forceMobileSize='xl'
                  level='h1'
                  margin='0 0 2rem'
                  lightColor={dark}
                >
                  {props.title}
                </Title>
                {props.tags &&
                  props.tags.map((tag) => (
                    <TagsWrapper key={tag}>
                      <Badge kind='light'>{tag}</Badge>
                    </TagsWrapper>
                  ))}
              </TitleContainer>

              {props.richText && (
                <Description>
                  <RichText data={props.richText} options={options} />
                </Description>
              )}

              {props.children && (
                <ContentWrapper>{props.children}</ContentWrapper>
              )}
            </TextContent>
            {props.ctaBlock}
            {props.featuredLinks && (
              <FeaturedLinkWrapper background={featuredBackgroundColor}>
                {React.Children.toArray(
                  props.featuredLinks.map((featuredLink) => {
                    return FeaturedLink({
                      ...featuredLink,
                      fontColor: fontColor,
                    });
                  }),
                )}
              </FeaturedLinkWrapper>
            )}
          </InfoContent>
        </ContentBlock>
      </Container>
    </TopBlockWrapper>
  );
};

export default RoundedTopBlock;
export const roundedTopBlockFragmentQuery = graphql`
  fragment RoundedTopBlockFragment on ContentfulTopBlock {
    __typename
    title
    selligentOfferName
    about {
      ...AboutFragment
    }
    richText {
      ...RichTextFragment
    }
    primaryLink {
      ...LinkFragment
    }
    secondaryLink {
      ...LinkFragment
    }
    image {
      gatsbyImageData(layout: FULL_WIDTH)
      ...ImageFragment
    }
    featuredLinks {
      ...FeaturedLinkFragment
    }
  }
`;
