import React from 'react';
import { graphql } from 'gatsby';

import Page, { PageType } from '@/components/Page';
import Content, {
  ContentBlocksProps,
  createContentItems,
} from '@/components/Content';
import { TopBlockType } from '@/components/blocks/TopBlock';
import RoundedTopBlock from '@/components/blocks/RoundedTopBlock';
import Link, { LinkStyle } from '@/components/Link';

type Props = ContentBlocksProps &
  PageType & {
    topBlock: TopBlockType;
  };

const DetailedProductPageTemplate: React.FC<Props> = (props) => {
  const { content } = props.data.contentfulPage.pageType;
  const contentItems = createContentItems(content, props);
  const backgroundColor: string = props.data.contentfulPage.headerBackground;
  const topBlock: TopBlockType = props.data.contentfulPage.pageType.topBlock;
  return (
    <Page {...props}>
      <RoundedTopBlock
        ctaBlock={
          <>
            {topBlock.primaryLink && (
              <Link {...topBlock.primaryLink} linkStyle={LinkStyle.PRIMARY} />
            )}
            {topBlock.secondaryLink && (
              <Link
                {...topBlock.secondaryLink}
                linkStyle={LinkStyle.SECONDARY}
              />
            )}
          </>
        }
        {...topBlock}
      />
      <Content
        pageColor={backgroundColor}
        items={contentItems}
        locale={props.pageContext.locale}
      />
    </Page>
  );
};

export default DetailedProductPageTemplate;

export const pageQuery = graphql`
  query DetailedProductPageQuery(
    $contentfulId: String!
    $locale: String
    $articles: [String]
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      seoMetadata {
        ...SeoMetaDataFragment
      }

      pageType {
        ... on ContentfulDetailedProductPageType {
          topBlock {
            ...RoundedTopBlockFragment
          }
          content {
            ...ContentFragment
          }
        }
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
    contentfulBlogPageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulSupportHomepageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
  }
`;
